.App {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
  background-color: rgb(82, 82, 82);
  color: lightgray;
  display: flex;
  justify-content: center;
  align-items: center;
}



.container {
  position: absolute;
  padding: 1.5 rem;
  height: 90%;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: 'center';
}

.cover{
  position:fixed;
  top:0;
  left:0;
  background-color: rgb(19, 19, 19);
  z-index:5;
  width:100%;
  height:100%;
}

.synthToolButton {
  /* -webkit-appearance: none; */
  /* min-height: fit-content; */
  font-size:20px;
  flex:1;
  min-height: 50px;
  align-items: 'center';
  background-color: 'lightgray';
  color: 'black';
  border-color: 'lightgray';
  box-shadow: 5;
  border-width: 2;
  border-radius: 5;
  width: 100%;
}


.heading-div {
  display:flex;
  flex-direction:row;
  flex: 1;
  width: 100%;
  align-items: 'center';
  justify-content: center;
}

.heading-label {
  flex: 1;
  display: inline;
  /* justify-content: center; */
  text-align: center;
}


.status-label {
  position: fixed;
  top: 10px;
  left: 10px;
  color: rgb(16, 148, 16);
  width: 100%;
  align-items: 'center';
}

.color-label {
  flex: 1;
  width: 100%;
  align-items: 'center';
}


.slider-label {
  flex: 1;
  width: 100%;
  align-items: 'center';
}

.colorPicker, .slider {
  flex: 1;
  width: 100%;
  margin-top:-.25rem;
  align-items: 'center';
  border-width: 0px;
}


.text-input {
  font-size:20px;
  min-height: 40px;
  width: 100%;
  align-items: 'center';
}

.text-div {
  flex: 1;
  width: 100%;
  align-items: 'center';
  justify-content: center;
}


.flask {
  flex:1;
  max-width: 2.5rem;
  max-height: 2.75rem;
  display: inline;
  padding-left: 10px;
  padding-right: 10px;
}

/* Container for the tabs */
.tab-container {
  display: flex;
  justify-content: center; /* Center-align the tabs */
  margin-bottom: 20px; /* Add space around the tabs */
}

/* Each tab element */
.tab {
  cursor: pointer;
  padding: 10px 20px;
  border: 1px solid #ccc;
  border-radius: 5px 5px 0 0; /* Rounded corners at the top */
  background-color: #f1f1f1;
  margin: 0 5px; /* Space between tabs */
  transition: background-color 0.3s;
  color: gray;
}

/* Active tab style */
.active-tab {
  background-color: #ffffff; /* White background for active tab */
  border-bottom: 1px solid white; /* Make the bottom border white to blend in */
  font-weight: bold;
  color: black;
}

/* Hover effect */
.tab:hover {
  background-color: #e7e7e7;
}

.texture-selection {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60vh; /* Take up 60% of the screen height */
  margin-top: 20px;
}

/* Scrollable container styling */
.scrollable-texture-grid {
  width: 100%;
  height: 100%; /* Fill the entire 60% height */
  overflow-y: auto; /* Enable vertical scrolling */
  border: 1px solid #ccc; /* Optional: Add border for visual separation */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Optional: Add shadow for depth */
  scrollbar-width: thin; /* Firefox: Make scrollbar thinner */
  scrollbar-color: #888 #f1f1f1; /* Firefox: Set thumb and track color */
}

/* Custom scrollbar styles for Chrome, Safari, and Edge */
.scrollable-texture-grid::-webkit-scrollbar {
  width: 16px; /* Increase width of the scrollbar */
}

.scrollable-texture-grid::-webkit-scrollbar-track {
  background: #f1f1f1; /* Light grey background for the track */
  border-radius: 10px;
}

.scrollable-texture-grid::-webkit-scrollbar-thumb {
  background: #888; /* Darker grey color for the thumb */
  border-radius: 10px;
  border: 3px solid #f1f1f1; /* Adds padding around the thumb */
}

.scrollable-texture-grid::-webkit-scrollbar-thumb:hover {
  background: #555; /* Darker color on hover */
}

/* Make the texture grid take up the entire scrollable container */
.texture-grid {
  display: flex;
  flex-direction: column; /* Arrange items in a single column */
  align-items: center; /* Center items horizontally */
  gap: 10px; /* Space between items */
  padding: 10px;
}

/* Ensure each texture item is full-width */
.texture-item {
  width: 90%; /* Adjust width to take up most of the container */
  cursor: pointer;
  border: 2px solid transparent;
  transition: border-color 0.3s ease;
}

.texture-item.selected {
  border-color: #00ff00; /* Highlight selected item with a green border */
}

/* Ensure each image fills the width of its container and maintains its aspect ratio */
.texture-thumbnail {
  width: 100%; /* Full width of the container */
  height: auto; /* Maintain aspect ratio */
  object-fit: contain; /* Scale image to fit within the box */
}
